import { tryFetch, IParams } from 'components/helpers/FetchApi';
interface TryLoginProps {
    username: string;
    password: string;
    token: string;
    csrf: string;
    location: {
        lat: string | null;
        lng: string | null;
    };
}
export interface TryLoginResponse {
    success: boolean,
    message: string,
    data: any
}

export const TryLogin = async ({ username, password, token, csrf, location }: TryLoginProps) => {
    const latitude = location.lat ? parseFloat(location.lat) : null;
    const longitude = location.lng ? parseFloat(location.lng) : null;

    let params: IParams = {
        url: '/authenticate',
        method: 'POST',
        payload: {
            username: username,
            password: password,
            token: token,
            location: {
                lat: latitude,
                lng: longitude,
            },
        },
        csrf,
    };
    return await tryFetch(params);
};
export const TryVerify =  async (csrf: string, token:string) => {
    let params: IParams = {
        url: `/verify/${token}`,
        method: "POST",
        payload: {},
        csrf
    }
    return await tryFetch(params);
}

export const TryResetPassword = async (payload:object, passcode:string, csrf:string) => {
    let params: IParams = {
        url: `/reset-password/${passcode}`,
        method: "POST",
        payload: payload,
        csrf
    }
    return await tryFetch(params);
}

export const GetUser = async (token:string) => {
    let params: IParams = {
        url: '/me',
        method: 'GET',
        payload: {},
        token, 
    }
    return await tryFetch(params);
}


export const GetUserPermission = async (csrf: string, token?:string) => {
    let params: IParams = {
        url: '/__p__',
        method: 'POST',
        payload: {},
        token, 
        csrf
    }
    return await tryFetch(params);
}

export const Idle = async (csrf: string, token?:string) => {
    let params: IParams = {
        url: '/idle',
        method: 'POST',
        payload: {},
        token,
        csrf
    }
    return await tryFetch(params);
}

export const Active = async (csrf: string, token?:string) => {
    let params: IParams = {
        url: '/active',
        method: 'POST',
        payload: {},
        token,
        csrf
    }
    return await tryFetch(params);
}

export const Logout = async (csrf: string, token?:string) => {
    let params: IParams = {
        url: '/logout',
        method: 'POST',
        payload: {},
        token, 
        csrf
    }
    return await tryFetch(params);
}