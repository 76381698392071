import AsyncComponent from '../helpers/AsyncComponent';

export const Login = AsyncComponent(() => 
    import('pages/security/Login')
        .then(module => module.default)
);

export const Verify = AsyncComponent(() => 
    import('pages/security/Verify')
        .then(module => module.default)
);

export const PasswordReset = AsyncComponent(() => 
    import('pages/security/PasswordReset')
        .then(module => module.default)
);

export const NotFound = AsyncComponent(() =>
    import('pages/common/404/NotFound')
        .then(module => module.default)    
);

export const Dashboard = AsyncComponent(() => 
    import('pages/dashboard/Index')
        .then(module => module.default)
);

export const Transactions = AsyncComponent(() => 
    import('pages/transactions/Index')
        .then(module => module.default)
);

export const Tickets = AsyncComponent(() => 
    import('pages/supports/Index')
        .then(module => module.default)
);

export const CreateTicket = AsyncComponent(() => 
    import('pages/supports/TicketInfo')
        .then(module => module.default)
);

export const TicketThread = AsyncComponent(() => 
    import('pages/supports/TicketThread')
        .then(module => module.default)
);

export const Messages = AsyncComponent(() => 
    import('pages/messages/Index')
        .then(module => module.default)
);

export const CreateTransaction = AsyncComponent(() => 
    import('pages/transactions/Create')
        .then(module => module.default)
);

export const CompleteTransactionPayment = AsyncComponent(() => 
    import('pages/transactions/PaymentComplete')
        .then(module => module.default)
);

export const Payments = AsyncComponent(() => 
    import('pages/payments/Index')
        .then(module => module.default)
);

export const Products = AsyncComponent(() => 
    import('pages/products/Index')
        .then(module => module.default)
);

export const CreateProduct = AsyncComponent(() => 
    import('pages/products/CreateSteps')
        .then(module => module.default)
);

export const EditProduct = AsyncComponent(() => 
    import('pages/products/CreateSteps')
        .then(module => module.default)
);

export const ProductWarehouse = AsyncComponent(() => 
    import('pages/products/Warehouse')
        .then(module => module.default)
);

export const Affiliates = AsyncComponent(() => 
    import('pages/affiliates/Index')
        .then(module => module.default)
);

export const AffiliateProducts = AsyncComponent(() => 
    import('pages/affiliates/Products')
        .then(module => module.default)
);

export const AffiliateStore = AsyncComponent(() => 
    import('pages/affiliates/Store')
        .then(module => module.default)
);

export const LiveSales = AsyncComponent(() => 
    import('pages/live/Sales')
        .then(module => module.default)
);

export const EConsult = AsyncComponent(() => 
    import('pages/econsult/Index')
        .then(module => module.default)
);

export const LiveTickets = AsyncComponent(() => 
    import('pages/live/Tickets')
        .then(module => module.default)
);

export const Leads = AsyncComponent(() => 
    import('pages/customers/Leads')
        .then(module => module.default)
);

export const Customers = AsyncComponent(() => 
    import('pages/customers/Index')
        .then(module => module.default)
);

export const AdminUsers = AsyncComponent(() => 
    import('pages/admin/Users')
        .then(module => module.default)
);

export const AdminCreateUser = AsyncComponent(() => 
    import('pages/admin/UserData')
        .then(module => module.default)
);

export const AdminEditUser = AsyncComponent(() => 
    import('pages/admin/UserData')
        .then(module => module.default)
);

export const AdminRoles = AsyncComponent(() => 
    import('pages/admin/Roles')
        .then(module => module.default)
);

export const AdminUserPermissions = AsyncComponent(() => 
    import('pages/admin/Permissions')
        .then(module => module.default)
);

export const AdminSettings = AsyncComponent(() => 
    import('pages/admin/Settings')
        .then(module => module.default)
);

export const Reports = AsyncComponent(() => 
    import('pages/reports/Index')
        .then(module => module.default)
);

export const ScheduledReports = AsyncComponent(() => 
    import('pages/reports/Scheduled')
        .then(module => module.default)
);



