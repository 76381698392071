export interface IGroup {
	group: string,
	menu: Array<IMenuData>
}

export interface IMenuData {
	id: string,
	name: string,
	path: string,
	order: number,
	icon: string,
	children: Array<IMenuData>
}

export interface INonMenuPath {
	id: string,
	name: string,
	path: string
}

export const MenuData: Array<IGroup> = [
	{
		group: "Main Menu",
		menu: [
			{
				id: btoa("_dashboard"),
				name: 'Dashboard',
				path: '/',
				order: 1,
				icon: 'dashboard',
				children: []
			},
			{
				id: btoa("_transactions"),
				name: 'Transactions',
				path: '/transactions',
				order: 2,
				icon: 'list_alt',
				children: []
			},
			{
				id: btoa("_tickets"),
				name: 'Tickets',
				path: '/tickets',
				order: 6,
				icon: 'support_agent',
				children: []
			},
			{
				id: btoa("_affiliate.products"),
				name: 'Manage Products',
				path: '/affiliates/products',
				order: 3,
				icon: 'shopping_bags',
				children: []
			},
			// {
			// 	id: btoa("_affiliate.stores"),
			// 	name: 'Manage Stores',
			// 	path: '/affiliates/store',
			// 	order: 4,
			// 	icon: 'storefront',
			// 	children: []
			// },
			{
				id: btoa("_payments"),
				name: 'Payments',
				path: '/payments',
				order: 5,
				icon: 'payments',
				children: []
			},
			{
				id: btoa("_monitoring"),
				name: 'Monitoring',
				path: '#',
				order: 7,
				icon: 'monitoring',
				children: [
					{
						id: btoa("_monitoring.live.sales"),
						name: 'Live Sales',
						path: '/monitoring/sales',
						order: 1,
						icon: '',
						children: []
					}
				]
			},
			{
				id: btoa("_consultations"),
				name: 'eConsult',
				path: '/econsult',
				order: 6,
				icon: 'event_available',
				children: []
			},
		]
	},
	{
		group: "Partners",
		menu: [
			{
				id: btoa("_affiliates"),
				name: 'Affiliates',
				path: '/affiliates',
				order: 3,
				icon: 'group',
				children: []
			},
			{
				id: btoa("_leads"),
				name: 'Leads',
				path: '/leads',
				order: 4,
				icon: 'record_voice_over',
				children: []
			},
			{
				id: btoa("_customers"),
				name: 'Customers',
				path: '/customers',
				order: 5,
				icon: 'connect_without_contact',
				children: []
			},
		]
	},
	{
		group: "Products",
		menu: [
			{
				id: btoa("_products"),
				name: 'Products',
				path: '#',
				order: 1,
				icon: 'local_mall',
				children: [
					{
						id: btoa("_products.view"),
						name: 'Products',
						path: '/products',
						order: 1,
						icon: '',
						children: []
					},
					{
						id: btoa("_warehouse"),
						name: 'Warehouse',
						path: '/warehouse',
						order: 2,
						icon: '',
						children: []
					},
				]
			},
		]
	},
	{
		group: "Reports",
		menu: [
			{
				id: btoa("_reports"),
				name: 'Reports',
				path: '#',
				order: 6,
				icon: 'leaderboard',
				children: [
					{
						id: btoa("_reports"),
						name: 'Reports',
						path: '/reports',
						order: 6,
						icon: '',
						children: [
							
						]
					},
					{
						id: btoa("_reports.scheduled"),
						name: 'Scheduled',
						path: '/reports/scheduled',
						order: 7,
						icon: '',
						children: [
							
						]
					}
				]
			},
		]
	},
	{
		group: "Settings",
		menu: [
			{
				id: btoa("_admin"),
				name: 'Admin',
				path: '#',
				order: 1,
				icon: 'manage_accounts',
				children: [
					{
						id: btoa("_admin.manage.users"),
						name: 'Manage Users',
						path: '/admin/manage-users',
						order: 1,
						icon: '',
						children: []
					},
					{
						id: btoa("_admin.manage.permissions"),
						name: 'Manage Permissions',
						path: '/admin/manage-permissions',
						order: 2,
						icon: '',
						children: []
					},
					{
						id: btoa("_admin.roles.manage"),
						name: 'Roles',
						path: '/admin/manage-roles',
						order: 3,
						icon: '',
						children: []
					},
				]
			},
			{
				id: btoa("_settings"),
				name: 'General Settings',
				path: '/settings',
				order: 5,
				icon: 'settings',
				children: []
			},
		]
	},
];

export const NonMenuPath: Array<INonMenuPath> = [
	{
		id: btoa("_profile"),
		name: "Profile",
		path: "/profile"
	},
	{
		id: btoa("_transactions.payment"),
		name: "Transactions Payment",
		path: "/transactions/payment"
	},
	{
		id: btoa("_transactions.create"),
		name: "Create Transaction",
		path: "/transactions/create"
	},
	{
		id: btoa("_transactions.edit"),
		name: "Edit Transaction",
		path: "/transactions/edit"
	},
	{
		id: btoa("_admin.create.user"),
		name: "Create User",
		path: "/admin/manage-users/create"
	},
	{
		id: btoa("_admin.edit.user"),
		name: "Edit User",
		path: "/admin/manage-users/edit"
	},
	{
		id: btoa("_products.create"),
		name: "Create Product",
		path: "/products/create"
	},
	{
		id: btoa("_products.edit"),
		name: "Edit Product",
		path: "/products/edit"
	},
	{
		id: btoa("_password.reset"),
		name: "Reset Password",
		path: "/password-reset"
	},
	{
		id: btoa("_tickets.create"),
		name: "Create ticket",
		path: "/tickets/create"
	},
	{
		id: btoa("_tickets.edit"),
		name: "Edit ticket",
		path: "/tickets/edit"
	},
	{
		id: btoa("_tickets.thread"),
		name: "Ticket Thread",
		path: "/tickets/thread"
	},
];