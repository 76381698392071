import React, { useEffect, useState } from 'react';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { Theme, makeStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        profileLink: {
            backgroundColor: theme.palette.primary.main,
            fontSize: "1rem",
            width: theme.spacing(4),
            height: theme.spacing(4)
        },
    }),
);

const StyledBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            backgroundColor: theme.palette.success.light,
            color: theme.palette.success.light,
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                border: '1px solid currentColor',
                animation: '$pulse 1.23s infinite linear',
                content: '""',
            },
        },

        '@keyframes pulse': {
            '0%': {
                opacity: .5
            },
            '16.7%': {
                opacity: 1
            },
            '33.32%': {
                opacity: .36
            },
            '49.48%': {
                opacity: 1
            },
            '66.64%': {
                opacity: .72
            },
            '83.3%': {
                opacity: 1
            },
            '99.96%': {
                opacity: .5
            }
        },
    }),
)(Badge);

const BadgeAvatar = ({ user, onClick }: { user: any, onClick: Function }) => {
    const [me, setMe] = useState<any>(null);

    useEffect(() => {
        setMe(user);
    }, [user])

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <IconButton
                color="primary"
                onClick={(e) => { onClick(e, "profile")}}
            >
                <StyledBadge
                    overlap="circular"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    variant="dot"
                >
                    <Avatar sizes="small" color="primary" className={classes.profileLink}>
                        {me?.fullname.substring(0, 1).toUpperCase()}
                    </Avatar>
                </StyledBadge>
            </IconButton>
        </div>
    )
}

export default BadgeAvatar