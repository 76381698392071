import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 250;
const collapsedWidth = 0;

const SidebarStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    borderRight: 0,
  },
  drawerOpen: {
    width: drawerWidth,
    background: theme.palette.grey.A400,
    color: theme.palette.grey.A200,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
  drawerClose: {
    background: theme.palette.grey.A400,
    color: theme.palette.grey.A200,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: collapsedWidth,
    display: 'flex',
    flexDirection: 'column',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    background: theme.palette.background.paper,
    ...theme.mixins.toolbar,
    boxShadow: '0 4px 4px -3px rgb(0 0 0 / 20%)',
  },
  brand: {
    marginLeft: '1rem !important',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  drawerContent: {
    flexGrow: 1,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  divider: {
    background: '#595959',
  },
  logoWrap: {
    textAlign: 'center',
  },
  logo: {
    width: '70%',
  },
}));

export default SidebarStyles;